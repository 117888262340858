
import GeneralWaitScreen from "components/General/WaitScreens/GeneralWaitScreen";
import { Playground } from "components/playground";
import { useAppSelector } from "configureStore";
import { useLoginClient } from "hooks/useHttpClient";
import AvailableFaresPage from "Pages/AvailableFaresPage";
import BookingDetailsPage from "Pages/BookingDetailsPage";
import BookingManagerPage from "Pages/BookingManagerPage";
import DeepLoginPage from 'Pages/DeepLoginPage';
import { EmailSignupConfirmationPage } from "Pages/EmailSignupConfirmationPage";
import { EmailUnsubscribeConfirmationPage } from "Pages/EmailUnsubscribeConfirmationPage ";
import FareDisplayPage from "Pages/FareDisplayPage";
import LoginPage from 'Pages/LoginPage';
import TestPage from 'Pages/TestPage';
import UnauthorizedPage from 'Pages/Unauthorized';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route } from 'react-router';
import { Navigate, Routes, To, useNavigate, useParams } from "react-router-dom";
import { Session_Reducer_isAdmin, Session_Reducer_SetFrontendSettings, Session_Reducer_SetToken } from "rootExports/SessionReducer";
import NoMatch from 'routes/NoMatch';
import "./pageTransition.css";
import HotelsPage from "Pages/HotelsPage";
import { State } from "rootExports/rootReducer";
import { useUserInformation } from "hooks/various";
import { loginBool_Reducer } from "rootExports/LoginReducer";
export const AppRoutes: React.FC<{}> = Allprops => {

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/weblogin/:routeWebname" element={<LoginPage />} />
      <Route path="/account/login/:routeWebname" element={<LoginPage />} />
      <Route path="/deep/:webid/:deeplinkid" element={<DeepLoginPage />} />
      <Route path="/signup/confirm/:code" element={<EmailSignupConfirmationPage />} />
      <Route path="/signup/unsubscribe/:code" element={<EmailUnsubscribeConfirmationPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/auth/:webid/:deeplinkid/*" element={<AuthorizedRoutes />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export const RequireAuth: React.FC<{ isAuthenticated: boolean, children: JSX.Element, redirectTo: To }> = ({ children, redirectTo, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export const AuthorizedRoutes: React.FC<{}> = Allprops => {
  const showBookingManagerTab = useSelector(
    (state: State) => state.Session.FrontendSettings.showBookingManagerTab
  );

  const otherSettings: string = useAppSelector((state: any) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
    }

    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  const settingArr = otherSettings.split("\n");
  const fieldContainer = settingArr[7] != undefined ? settingArr[7].split("#") : [];
  const mustLoginBool: boolean = fieldContainer?.includes('MUSTLOGIN');

  const isLoggedIn = useAppSelector((state) => state.Session.isLoggedIn);
  const LoginReduxBool: any = useAppSelector((state: any) => state.LoginBool);
  let { webid, deeplinkid } = useParams();
  const loginClient = useLoginClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useUserInformation();
  function OnLogout() {
    window.location.href = `/weblogin/${userInfo?.WebName}`;
  }
  const loginBoolStr = sessionStorage.getItem("loginBool");
  const isLogin = loginBoolStr ? JSON.parse(loginBoolStr) : false;

  useEffect(() => {
    if (!isLogin) {
      if (mustLoginBool && !LoginReduxBool?.value) {
        dispatch(loginBool_Reducer(true));
        OnLogout();
      }
    }
  }, [isLogin, mustLoginBool, LoginReduxBool, dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      // console.log(`AUTHORIZED Route render: ${deeplinkid ? `| Deeplink: ${deeplinkid}` : ""}`);
    }
    else {
      loginClient.loginByDeeplink(webid!, deeplinkid!, undefined).then(e => {
        if (e.success) {
          dispatch(Session_Reducer_isAdmin(e.isAdmin!))
          dispatch(Session_Reducer_SetToken(e.token!));
          dispatch(Session_Reducer_SetFrontendSettings(e.agentFrontendSettings!));
        }
        else {
          navigate("/unauthorized")
        }
      }).catch(() => {
        navigate("/unauthorized")
      })
    }
  }, [isLoggedIn, webid, deeplinkid, loginClient, dispatch, navigate])
  if (isLoggedIn) {

    return <Routes>
      <Route path="/faresearch" element={
        <AvailableFaresPage />
      } />
      <Route path="/availfares" element={
        <AvailableFaresPage />
      } />
      <Route path="/hotels" element={
        <HotelsPage />
      } />
      <Route path="/faredisplay" element={
        <FareDisplayPage />
      } />
      <Route path="/bookingmanager/*" element={
        <BookingManagerPage />
      } />

      <Route
        path="/bookingManager"
        element={
          showBookingManagerTab ? <BookingManagerPage /> : <Navigate to="../availfares" />
        }
      />

      <Route path="/bookingdetails/:cartid/:bookingid" element={
        <BookingDetailsPage />
      } />
      <Route path="/bookingdetails/:filekey" element={
        <BookingDetailsPage />
      } />
      <Route path="/test/:logo/:pagePath" element={<TestPage />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  }
  else {
    return <GeneralWaitScreen show={true}></GeneralWaitScreen>
  }
}



